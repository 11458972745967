<template lang="html">

  <v-container>

    <div class="subtitle-1">Custom URL Params</div>
    <div class="mb-5">Will be appended to the landing page URL as part of the query string during redirect.</div>

    <v-row v-for="(param, idx) in params" :key="idx">
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="param.key"
          label="Key"
          outlined
          hide-details
          dense
          class="param-input"
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="param.value"
          label="Value"
          outlined
          hide-details
          dense
          append-outer-icon="mdi-delete"
          @click:append-outer="removeParam(idx)"
          class="param-input"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row align="center" justify="space-around">
      <v-btn @click="addParam" small depressed>
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-row>
  </v-container>

</template>

<script>
export default {

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      params: []
    }
  },

  watch: {

    value: {
      handler: function(newVal) {
        this.params = newVal
      }
    },

    params: {
      handler: 'update',
      deep: true
    }

  },

  methods: {

    update () {
      this.$emit('input', this.params);
    },

    addParam () {
      this.params.push({
        key: '', value: ''
      })
    },

    removeParam (index) {
      this.params.splice(index, 1)
    }

  }

}
</script>

<style lang="css" scoped>

.param-input {
  font-family: 'Courier'
}

</style>
