<template lang="html">

  <div class="templates">

    <v-toolbar outlined elevation="0">
      <v-toolbar-title>Templates</v-toolbar-title>
    </v-toolbar>

    <v-container fluid>

      <v-card outlined>

        <v-data-table
        :headers="headers"
        :items="templates"
        :items-per-page="100"
        hide-default-footer
        >

        <template v-slot:item.name="{ item }">
          <a @click="showTemplate(item)">{{ item.name }}</a>
        </template>

        <template v-slot:item.html_template="{ item }">
          <URLPreview :url="item.html_template" />
        </template>

      </v-data-table>

    </v-card>

    </v-container>

    <v-navigation-drawer
      v-model="drawer"
      fixed

      temporary
      right
      hide-overlay
      width="400"
      class="elevation-0"
    >
      <v-sheet
        color="primary"
        dark
        class="pa-4"
      >
        <div>{{ selectedTemplate.name }}</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-sheet
        color="grey lighten-3"
        class="pa-2"
      >
        URL Template
      </v-sheet>

      <v-sheet
        color="grey lighten-5"
        class="pa-4"
      >
        <pre><code>{{ JSON.stringify(selectedTemplate.url_template, null, 2) }}</code></pre>
      </v-sheet>

      <v-sheet
        color="grey lighten-3"
        class="pa-2"
      >
        URL Matcher
      </v-sheet>

      <v-sheet
        color="grey lighten-5"
        class="pa-4"
      >
        <pre><code>{{ JSON.stringify(selectedTemplate.url_matcher, null, 2) }}</code></pre>
      </v-sheet>

      <v-sheet
        color="grey lighten-3"
        class="pa-2"
      >
        Failure detector
      </v-sheet>

      <v-sheet
        color="grey lighten-5"
        class="pa-4"
      >
        <pre><code>{{ JSON.stringify(selectedTemplate.failure_detector, null, 2) }}</code></pre>
      </v-sheet>

    </v-navigation-drawer>

  </div>

</template>

<script>

  import URLPreview from '@/components/URLPreview.vue'
  import { fetchTemplates } from '@/api'

  export default {
    components: { URLPreview },
    data () {
      return {
        headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Used', value: 'affiliations_count' },
          { text: 'Template', value: 'html_template' }
        ],
        loading: false,
        templates: [],
        drawer: false,
        selectedTemplate: {}
      }
    },

    created (){
      this.getDataFromApi()
    },

    methods: {

      showTemplate (item) {
        this.selectedTemplate = item
        this.drawer = true
      },

      getDataFromApi () {
        this.loading = true
        fetchTemplates().then(data => {
          this.templates = data
          this.loading = false
        })
      }

    }

  }
</script>
