import axios from 'axios'

export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000,
  withCredentials: true
})

export function authenticate (username, password) {
  return http.post('login', { username, password })
    .then(response => response.data)
}

export function signout () {
  return http.post('logout').then(response => response.data)
}

export function whoami () {
  return http.get('me').then(resp => resp.data)
}

export function importAffiliations (csv) {
  return http.post('import', { csv }).then(resp => resp.data)
}

export function searchPartners (term) {
  return http.get(`partners/search`, { params: { query: term } })
    .then(resp => resp.data)
}

export function fetchSimilarAffiliations(medium_id, partner_id) {
  return http.post('affiliations/lookup', { medium_id, partner_id })
    .then(resp => resp.data)
}

export function fetchBrokenAffiliations () {
  return http.get('affiliations/broken').then(resp => resp.data)
}

export function fetchRecentAffiliations () {
  return http.get('affiliations/recent').then(resp => resp.data)
}

export function fetchMedia () {
  return http.get(`media/`)
    .then(resp => resp.data)
}

export function fetchAffiliations (mediumId) {
  return http.get(`media/${mediumId}/affiliations`)
    .then(resp => resp.data)
}

export function removeAffiliation (id) {
  return http.delete(`affiliations/${id}`).then(resp => resp.data)
}

export function promoteAffiliation (id) {
  return http.post(`affiliations/${id}/promote`).then(resp => resp.data)
}

export function createAffiliation (params) {
  return http.post('affiliations', params).then(resp => resp.data)
}

export function detectTemplate (url) {
  return http.post('networks/detect', { url }).then(resp => resp.data)
}

export function fetchPartners (params) {
  return http.get(`partners/`, { params })
    .then(resp => resp.data)
}

export function fetchTemplates () {
  return http.get(`networks/`)
    .then(resp => resp.data)
}

export function fetchIssues () {
  return http.get(`issues/`)
    .then(resp => resp.data)
}
