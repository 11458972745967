import Vue from 'vue'
import VueRouter from 'vue-router'
import Partners from '../views/Partners.vue'
import Issues from '../views/Issues.vue'
import Templates from '../views/Templates.vue'
import Broken from '../views/Broken.vue'
import Media from '../views/Media.vue'
import Import from '../views/Import.vue'
import Affiliations from '../views/Affiliations.vue'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    component: Dashboard,
    meta: { auth: true },
    props: true,
    children: [
      {
        path: '/',
        redirect: '/media'
      },
      {
        path: '/media',
        name: 'media',
        component: Media,
        meta: { auth: true }
      },
      {
        path: '/media/:mediumId/affiliations',
        name: 'affiliations',
        component: Affiliations,
        props: true,
        meta: { auth: true }
      },
      {
        path: '/import',
        name: 'import',
        component: Import,
        meta: { auth: true }
      },
      {
        path: '/partners',
        name: 'partners',
        component: Partners,
        meta: { auth: true }
      },
      {
        path: '/issues',
        name: 'issues',
        component: Issues,
        meta: { auth: true }
      },
      {
        path: '/broken',
        name: 'broken',
        component: Broken,
        meta: { auth: true }
      },
      {
        path: '/templates',
        name: 'templates',
        component: Templates,
        meta: { auth: true }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
