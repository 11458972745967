import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import { http } from '@/api'
import * as filters from './utils/filters'
import store from '@/store.js'

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    notify (msg) {
      store.notification = msg
      store.notificationId = Math.random();
    }
  }
})

http.interceptors.response.use(response => {
  return response
}, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('isAuthenticated')
  }
  // Use the error from API JSON response
  if (error.response && error.response.data.error) {
    error.message = error.response.data.error
  }
  return Promise.reject(error)
})

router.beforeEach((to, from, next) => {
  var requiresAuth = to.matched.some(record => record.meta.auth)
  var loggedIn = !!localStorage.getItem('isAuthenticated')
  if (requiresAuth && !loggedIn) {
    next('/login')
  } else if (to.path == '/login' && loggedIn) {
    next('/')
  } else {
    next()
  }
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
