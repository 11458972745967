import moment from 'moment'

export function formatDate (value) {
  if (value) {
    return moment(String(value)).format('MMM Do \'YY')
  }
}

export function timeAgo (value) {
  if (value) {
    return moment(String(value)).fromNow()
  }
}
