<template lang="html">

  <div class="media">

    <v-toolbar outlined elevation="0">
      <v-toolbar-title>Media</v-toolbar-title>
    </v-toolbar>

    <v-container fluid>

      <v-row>
        <v-col cols="9">

          <v-card outlined>

          <v-data-table
            :headers="headers"
            :items="media"
            :items-per-page="-1"
            hide-default-footer
          >

          <template v-slot:item.name="{ item }">
            <router-link :to="{ name: 'affiliations', params: { mediumId: item.id }}">
              {{ item.name }}
            </router-link>
          </template>

          <template v-slot:item.canonical_name="{ item }">
            <v-chip small outlined label>{{ item.canonical_name }}</v-chip>
          </template>

          </v-data-table>

          </v-card>

        </v-col>

        <v-col>

          <v-card dark color="primary">

            <v-list color="transparent" dense two-line>

              <template v-for="(item, index) in recentAffiliations">
                <v-list-item :key="item.title">

                  <v-list-item-content>
                    <v-list-item-title class="secondary--text" v-text="item.partner"></v-list-item-title>
                    <v-list-item-subtitle>{{ item.network }} @ {{ item.medium }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text>
                      {{ item.created_at | timeAgo }}
                    </v-list-item-action-text>
                  </v-list-item-action>

                </v-list-item>

                <v-divider
                  v-if="index < recentAffiliations.length - 1"
                  :key="index"
                ></v-divider>
              </template>

            </v-list>

          </v-card>

        </v-col>


      </v-row>

    </v-container>

  </div>

</template>

<script>

  import { fetchMedia, fetchRecentAffiliations } from '@/api'

  export default {
    data () {
      return {
        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Canonical Name', value: 'canonical_name' },
        ],
        loading: false,
        media: [],
        recentAffiliations: []
      }
    },

    created (){
      this.getDataFromApi()
    },

    methods: {

      getDataFromApi () {
        this.loading = true
        fetchMedia().then(data => {
          this.media = data
          this.loading = false
        })
        fetchRecentAffiliations().then(data => {
          this.recentAffiliations = data
        })
      }

    }

  }
</script>

<style lang="css" scoped>
</style>
