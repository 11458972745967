<template lang="html">

  <div class="import">

    <v-toolbar outlined elevation="0">
      <v-toolbar-title>Import</v-toolbar-title>
    </v-toolbar>

    <v-container style="max-width: 600px">

        <v-card class="mt-10 pa-5" title outlined>

          <v-card-text>

            <p>Import multiple affiliations by copy-pasting CSV into the input field. See example below.</p>

            <v-sheet color="grey lighten-3" class="my-3 pa-3">
              shopia-dk,example_dk,http://network.com/tracking/path
            </v-sheet>

            <v-textarea
              v-model="csv"
              label="CSV"
              outlined
              required
            ></v-textarea>

            <v-divider></v-divider>

            <v-card-actions class="mt-5">

              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                depressed
                @click="importCSV"
                :loading="loading"
                :disabled="loading"
              >
                Import
              </v-btn>

            </v-card-actions>

          </v-card-text>

        </v-card>

        <v-simple-table v-if="results.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Id</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="result.id" v-for="result in results">
                <td>{{result.id}}</td>
                <td>
                  <span v-bind:class="[result.error ? 'red--text' : 'green--text']">
                    {{result.error || "Added"}}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

      </v-container>

  </div>

</template>

<script>
import { importAffiliations } from '@/api'
export default {
  data () {
    return {
      body: '',
      response: {},
      loading: false
    }
  },
  computed: {
    results () {
      return this.response.results || []
    },
  },
  methods: {
    importCSV () {

      this.errorMessage = null
      this.loading = true

      importAffiliations(this.csv)
        .then((resp) => {
          this.response = resp
          this.loading = false
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage = error.response.data.error
          } else if (error.request) {
            this.errorMessage = 'No response from server'
          } else {
            this.errorMessage = '500 Internal Server Error'
          }
          this.loading = false
        })
    }
  }
}
</script>
