var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"affiliations"},[_c('v-toolbar',{attrs:{"outlined":"","elevation":"0"}},[_c('v-toolbar-title',[_vm._v("Affiliations")]),_c('v-spacer'),_c('v-text-field',{attrs:{"dense":"","flat":"","hide-details":"","placeholder":"Search by name","solo-inverted":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.affiliations,"search":_vm.searchQuery,"items-per-page":50,"footer-props":{ itemsPerPageOptions: [50] }},scopedSlots:_vm._u([{key:"item.preloaded_affiliate_url",fn:function(ref){
var item = ref.item;
return [_c('URLPreview',{attrs:{"html":false,"url":item.preloaded_affiliate_url}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-chip',{attrs:{"label":"","small":"","dark":"","color":"green"}},[_vm._v("active")]):_vm._e()]}},{key:"item.network_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":""}},[_vm._v(_vm._s(item.network_name || 'custom'))])]}},{key:"item.custom_params",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toQuerystring(item.custom_params))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1),(!item.active)?_c('v-btn',{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.promote(item)}}},[_vm._v("Promote")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }