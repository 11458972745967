<template>

  <v-card class="elevation-1 pa-5">

    <v-card-text class="text-center black--text">
      <div class="title">AdPilot</div>
      <div class="overline">A link manager tool</div>
    </v-card-text>

    <v-card-text>
      <v-form id="login-form" @submit.prevent="login">
        <v-text-field outlined v-model="email" name="login" label="Username" type="text"></v-text-field>
        <v-text-field outlined id="password" v-model="password"  name="password" label="Password" type="password"></v-text-field>
      </v-form>

      <v-alert
        v-if="hasError"
        dense
        type="error"
        dark
      >{{ errorMessage }}</v-alert>

      <v-btn form="login-form" block depressed :disabled="loading" :loading="loading" type="submit" color="primary">
        Log in
      </v-btn>

    </v-card-text>

  </v-card>

</template>

<script>

import { authenticate } from '@/api'

export default {
  data() {
    return {
      email : "",
      password : "",
      loading: false,
      hasError: false,
      errorMessage: ''
    }
  },

  computed: {
  },

  watch: {
  },

  methods: {
    login () {
      this.loading = true
      this.hasError = false
      this.errorMessage = ''
      const username = this.email
      const password = this.password
      authenticate(username, password).then(() => {
          localStorage.setItem("isAuthenticated", 'yes')
          this.$router.push({ name: 'media' })
      }).catch(err => {
        this.loading = false
        this.hasError = true
        this.errorMessage = err.message
      })
    }
  }
}

</script>

<style scoped>
  .login-error {
    text-align: center;
    color: #e40000;
  }
</style>
