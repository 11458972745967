var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"broken"},[_c('v-toolbar',{attrs:{"outlined":"","elevation":"0"}},[_c('v-toolbar-title',[_vm._v("Broken links")])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-4",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.broken,"items-per-page":100,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.test_url",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","href":item.test_url,"target":"_blank","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-open-in-new ")])],1)]}},{key:"item.error_message",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"red","dark":"","label":""}},[_vm._v(_vm._s(item.error_message))])]}},{key:"item.validated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("timeAgo")(item.validated_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Remove affiliation ")],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }