<template>

  <v-dialog v-model="show" persistent max-width="800px">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="headline">Add new affiliation</span>
      </v-card-title>
      <v-card-text>

        <v-banner>Insert the affiliate URL you want to add and AdPilot will match it against a template and make sure it's correctly formatted.</v-banner>

        <v-container>
          <v-row no-gutters>

            <v-col cols="12">
              <v-text-field
                v-model.trim="affiliation.url"
                label="URL"
                placeholder="https://"
                outlined
                required
                autocomplete="off"
                @change="detectUsedTemplate"
              >
              </v-text-field>

            </v-col>

            <v-col cols="12" sm="12">
              <v-select
                :items="media"
                label="Medium"
                item-text="name"
                item-value="id"
                required
                outlined
                v-model="affiliation.medium_id"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12">
              <v-autocomplete
                v-model="affiliation.partner_id"
                :items="partners"
                :loading="isSearching"
                :search-input.sync="search"
                hide-no-data
                hide-selected
                outlined
                item-text="name"
                item-value="id"
                label="Partner"
                placeholder="Start typing to Search"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12">
              <v-select
                :items="strategies"
                label="Template"
                required
                v-model="affiliation.strategy"
                outlined
                :hint="detectedTemplateText"
                persistent-hint
              ></v-select>
            </v-col>

            <v-alert dense type="warning" v-show="isCustom">
              Custom URLs are error prone and may contain human errors. Consider adding a new template instead.
            </v-alert>

            <CustomParamsForm :value="affiliation.custom_params" @input="affiliation.custom_params = $event" />

            <v-col class="mt-5" cols="12" sm="12">

              <v-divider></v-divider>

              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="item in similar"
                      :key="item.id"
                    >
                      <td>{{ item.created_at }}</td>
                      <td>{{ item.summary }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="affiliation.promote"
                    label="Promote this URL"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <p class="pt-4">Use this URL even if another already exists.</p>
                </v-col>
              </v-row>

            </v-col>

          </v-row>

          <v-alert v-if="hasError" type="error">{{ errorMessage }}</v-alert>

        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">Close</v-btn>
        <v-btn color="primary" text :disabled="loading" :loading="loading" @click="save">Save</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>

import { fetchMedia, searchPartners, fetchSimilarAffiliations,
  detectTemplate, createAffiliation } from '@/api'

import CustomParamsForm from '@/components/CustomParamsForm.vue'

export default {
  name: 'ConnectionForm',
  components: { CustomParamsForm },
  props: {
    show: { type: Boolean, required: true, default: false }
  },
  data () {
    return {
      loading: false,
      affiliation: {
        medium_id: null,
        partner_id: null,
        url: '',
        promote: true,
        strategy: 'auto',
        custom_params: []
      },
      strategies: [
        { value: 'auto', text: 'Auto detect' },
        { value: 'custom', text: 'Custom' }
      ],
      media: [],
      isSearching: false,
      search: null,
      partners: [],
      similar: [],
      detectedTemplate: '',
      hasError: false,
      errorMessage: null
    }
  },

  created () {
    this.getMedia()
  },

  watch: {
    search (val) {
      val && val !== this.affiliation.partner_id && this.queryPartners(val)
    },
    "affiliation.medium_id": "checkExisting",
    "affiliation.partner_id": "checkExisting"
  },

  computed: {

    detectedTemplateText () {
      if (this.detectedTemplate) {
        return `Detected template: ${this.detectedTemplate}`
      } else {
        return ''
      }
    },

    isCustom () {
      return this.affiliation.strategy === "custom"
    }

  },

  methods: {

    detectUsedTemplate () {
      if (this.affiliation.url) {
        detectTemplate(this.affiliation.url).then(data => {
          this.detectedTemplate = data.template_name
        })
      }
    },

    resetForm () {
      this.affiliation = {
        medium_id: null,
        partner_id: null,
        url: '',
        promote: true,
        strategy: 'auto',
        custom_params: []
      }
      this.similar = []
      this.hasError = false
      this.loading = false
      this.isSearching = false
      this.errorMessage = null
    },

    checkExisting () {
      if (this.affiliation.medium_id && this.affiliation.partner_id) {
        fetchSimilarAffiliations(this.affiliation.medium_id, this.affiliation.partner_id).then(data => {
          this.similar = data
        })
      }
    },

    save () {

      const payload = {
        url: this.affiliation.url,
        partner_id: this.affiliation.partner_id,
        medium_id: this.affiliation.medium_id,
        custom: this.isCustom,
        promote: this.affiliation.promote,
        custom_params: this.affiliation.custom_params
      }

      this.loading = true
      this.hasError = false
      this.errorMessage = null

      createAffiliation(payload).then(() => {
        this.loading = false
        this.notify('Affiliation was succesfully added!')
        this.close()
      }).catch(err => {
        this.errorMessage = err
        this.hasError = true
        this.loading = false
      })

    },

    close () {
      this.$emit('update:show', false)
      this.resetForm()
    },

    queryPartners (v) {
      this.isSearching = true
      searchPartners(v).then(data => {
        this.partners = data
        this.isSearching = false
      })
    },

    getMedia () {
      fetchMedia().then(data => {
        this.media = data
      })
    }

  }

}
</script>

<style lang="css" scoped>
</style>
