<template lang="html">

  <div class="issues">

    <v-toolbar outlined elevation="0">
      <v-toolbar-title>Issues</v-toolbar-title>
    </v-toolbar>

    <v-container fluid>

      <v-card outlined class="pa-4">

      <v-data-table
        :headers="headers"
        :items="issues"
        :items-per-page="-1"
        show-expand
        hide-default-footer
      >

      <template v-slot:item.timestamp="{ item }">
        {{ item.timestamp | timeAgo }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table dense class="pa-4">
            <tbody>
               <tr :key="key" v-for="(value, key) in item.request_payload">
                  <td>{{ key }}</td>
                  <td>{{ value }}</td>
               </tr>
            </tbody>
          </v-simple-table>
        </td>
      </template>

      </v-data-table>

      </v-card>

    </v-container>

  </div>

</template>

<script>

  import { fetchIssues } from '@/api'

  export default {
    data () {
      return {
        headers: [
          {
            text: 'Date',
            align: 'start',
            sortable: false,
            value: 'timestamp',
          },
          { text: 'Issue', value: 'error_message' },
        ],
        loading: false,
        issues: []
      }
    },

    created (){
      this.getDataFromApi()
    },

    methods: {

      getDataFromApi () {
        this.loading = true
        fetchIssues().then(data => {
          this.issues = data
          this.loading = false
        })
      }

    }

  }
</script>

<style lang="css">

.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}

</style>
