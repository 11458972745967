<template lang="html">

  <div class="broken">

    <v-toolbar outlined elevation="0">
      <v-toolbar-title>Broken links</v-toolbar-title>
    </v-toolbar>

    <v-container fluid>

      <v-card outlined class="pa-4">

      <v-data-table
        :headers="headers"
        :items="broken"
        :items-per-page="100"
        hide-default-footer
      >

      <template v-slot:item.test_url="{ item }">
        <v-btn color="primary" :href="item.test_url" target="_blank" icon>
          <v-icon small>
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.error_message="{ item }">
        <v-chip small color="red" dark label>{{ item.error_message }}</v-chip>
      </template>

      <template v-slot:item.validated_at="{ item }">
        {{ item.validated_at | timeAgo }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn small text @click="remove(item)">
          <v-icon left>
            mdi-delete
          </v-icon>
          Remove affiliation
        </v-btn>
      </template>

      </v-data-table>

      </v-card>

    </v-container>

  </div>

</template>

<script>

  import { fetchBrokenAffiliations, removeAffiliation } from '@/api'

  export default {
    data () {
      return {
        headers: [
          {
            text: 'Detected at',
            align: 'start',
            sortable: false,
            value: 'validated_at',
          },
          { text: 'Affiliation', value: 'summary' },
          { text: 'Error', value: 'error_message' },
          { text: 'Test URL', value: 'test_url' },
          { text: 'Actions', value: 'actions' }
        ],
        broken: []
      }
    },

    created (){
      this.getDataFromApi()
    },

    methods: {

      remove (item) {
        if (!confirm('Are you sure?')) {
          return
        }
        removeAffiliation(item.id).then(() => {
          this.broken.splice(this.broken.indexOf(item), 1);
          this.notify('Affiliation was removed permanently!')
        })
      },

      getDataFromApi () {
        this.loading = true
        fetchBrokenAffiliations().then(data => {
          this.broken = data
          this.loading = false
        })
      }

    }

  }
</script>

<style lang="css" scoped>
</style>
