<template>
  <v-app id="adpilot">
    <v-navigation-drawer
      v-model="drawer"
      app
      class="pt-4"
      color="primary"
      dark
      permanent
    >

      <v-list>
        <v-list-item
          v-for="[icon, text, routeName] in links"
          :key="icon"
          link
          :to="{ name: routeName }"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="showDialog">
          <v-list-item-icon>
            <v-icon>mdi-link-variant-plus</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Add URL</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>

    <v-main class="main">
      <router-view></router-view>
    </v-main>

    <AffiliationForm :show.sync="dialog"></AffiliationForm>

    <v-snackbar
      color="primary"
      v-model="snackbar"
    >
      {{ notification }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="yellow"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-app>
</template>

<script>

import AffiliationForm from '@/components/AffiliationForm.vue'
import store from '@/store.js'
import { signout } from '@/api'

export default {
  name: 'App',

  components: {
    AffiliationForm
  },

  data: () => ({
    snackbar: false,
    drawer: null,
    dialog: false,
    links: [
      ['mdi-apps', 'Media', 'media'],
      ['mdi-storefront', 'Partners', 'partners'],
      ['mdi-format-textbox', 'Templates', 'templates'],
      ['mdi-alert', 'Lost traffic', 'issues'],
      ['mdi-comment-alert', 'Broken links', 'broken'],
      ['mdi-upload', 'Import', 'import']
    ]
  }),

  watch: {
    notificationId () {
      this.snackbar = true
    }
  },

  computed: {

    notificationId () {
      return store.notificationId
    },

    notification () {
      return store.notification
    }

  },

  methods: {

    showDialog () {
      this.dialog = true
    },

    logout () {
      signout().then(() => {
        localStorage.removeItem('isAuthenticated')
        this.$router.push({ name: 'login' })
      })
    }

  }

};
</script>

<style>
  #adpilot {
    font-family: 'Futura'
  }

  .main {
    background: #f2f3f5;
  }
</style>
