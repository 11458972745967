<template lang="html">

  <div class="affiliations">

    <v-toolbar outlined elevation="0">
      <v-toolbar-title>Affiliations</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-text-field
        dense
        flat
        hide-details
        placeholder="Search by name"
        solo-inverted
        v-model="searchQuery"
      ></v-text-field>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

    </v-toolbar>

    <v-container fluid>

      <v-card outlined>

      <v-data-table
        :headers="headers"
        :items="affiliations"
        :search="searchQuery"
        :items-per-page="50"
        :footer-props="{ itemsPerPageOptions: [50] }"
      >

      <template v-slot:item.preloaded_affiliate_url="{ item }">
        <URLPreview :html="false" :url="item.preloaded_affiliate_url" />
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip label small dark color="green" v-if="item.active">active</v-chip>
      </template>

      <template v-slot:item.network_name="{ item }">
        <v-chip small outlined label>{{ item.network_name || 'custom' }}</v-chip>
      </template>

      <template v-slot:item.custom_params="{ item }">
        {{ toQuerystring(item.custom_params) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn small icon @click="remove(item)">
          <v-icon
            small
          >
            mdi-delete
          </v-icon>
        </v-btn>
        <v-btn v-if="!item.active" small text color="primary" @click="promote(item)">Promote</v-btn>
      </template>

      </v-data-table>

      </v-card>

    </v-container>

  </div>

</template>

<script>

  import URLPreview from '@/components/URLPreview.vue'
  import { fetchAffiliations, removeAffiliation, promoteAffiliation } from '@/api'

  export default {
    components: { URLPreview },
    props: ['mediumId'],
    data () {
      return {
        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Partner', value: 'partner_name' },
          { text: 'Template', value: 'network_name' },
          { text: 'URL', value: 'preloaded_affiliate_url' },
          { text: 'Custom params', value: 'custom_params' },
          { text: 'Status', value: 'status' },
          { text: 'Actions', value: 'actions' }
        ],
        loading: false,
        affiliations: [],
        searchQuery: ''
      }
    },

    created (){
      this.getDataFromApi()
    },

    methods: {

      toQuerystring (params) {
        const sp = new URLSearchParams(params);
        return sp.toString();
      },

      promote (item) {
        if (!confirm('Are you sure?')) {
          return
        }
        promoteAffiliation(item.id).then(() => {
          this.notify('Affiliation was succesfully promoted!')
        })
      },

      remove (item) {
        if (!confirm('Are you sure?')) {
          return
        }
        removeAffiliation(item.id).then(() => {
          this.affiliations.splice(this.affiliations.indexOf(item), 1);
          this.notify('Affiliation was removed permanently!')
        })
      },

      getDataFromApi () {
        this.loading = true
        fetchAffiliations(this.mediumId).then(data => {
          this.affiliations = data
          this.loading = false
        })
      }

    }

  }
</script>

<style lang="css" scoped>
</style>
