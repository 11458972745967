<template lang="html">
  <span v-if="html" class="template" v-html="url"></span>
  <span v-else class="template">{{ url }}</span>
</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
    html: { type: Boolean, required: false, default: true }
  }
}
</script>

<style lang="css" scoped>
  .template { font-size: 0.8em; font-family: MONOSPACE; }
  .template >>> .placeholder { background: #e6f4ff; color: #2107b8; }
  .template >>> .dynamic { color: #008e00; background: #ddf3dd; }
</style>
