<template lang="html">

  <div class="partners">

    <v-toolbar outlined elevation="0">

      <v-toolbar-title>Partners</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-text-field
        dense
        flat
        hide-details
        placeholder="Search partner by name"
        solo-inverted
        v-model="searchQuery"
        autocomplete="off"
      ></v-text-field>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

    </v-toolbar>

    <v-container fluid>

      <v-card outlined class="pa-4">

      <v-data-table
        :headers="headers"
        :items="partners"
        :items-per-page="25"
        :server-items-length="totalCount"
        :footer-props="{ itemsPerPageOptions: [25] }"
        :options.sync="options"
        :loading="loading"
      >

        <template v-slot:item.canonical_name="{ item }">
          <v-chip small outlined label>{{ item.canonical_name }}</v-chip>
        </template>

        <template v-slot:item.templates="{ item }">
          <v-chip v-for="n in item.networks" :key="n.id" small outlined label>{{ n.name }}</v-chip>
        </template>

        <template v-slot:item.media="{ item }">
          <v-chip v-for="m in item.media" :key="m.id" small outlined label>{{ m.name }}</v-chip>
        </template>

      </v-data-table>

      </v-card>

    </v-container>

  </div>

</template>

<script>

  import { fetchPartners } from '@/api'

  export default {
    data () {
      return {
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Canonical name', value: 'canonical_name' },
          { text: 'Website', value: 'domain' },
          { text: 'Templates', value: 'templates' },
          { text: 'Media', value: 'media' }
        ],
        options: { page: 1 },
        loading: false,
        partners: [],
        searchQuery: '',
        searchTimerId: null,
        totalCount: 0
      }
    },

    created (){
      this.getDataFromApi()
    },

    watch: {
      searchQuery () {
        this.triggerSearch()
      },
      'options.page': 'getDataFromApi'
    },

    methods: {

      triggerSearch () {
        clearTimeout(this.searchTimerId)
        this.searchTimerId = setTimeout(() => {
          this.options.page = 1
          this.getDataFromApi()
        }, 500)
      },

      getDataFromApi () {
        this.loading = true

        const { page, itemsPerPage } = this.options
        const queryParams = { query: this.searchQuery, limit: itemsPerPage, page }

        fetchPartners(queryParams).then(data => {
          this.partners = data.items
          this.totalCount = data.total_items
          this.loading = false
        })
      }
    }

  }
</script>

<style lang="css" scoped>
</style>
